import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import './App.css';
import Main from './Main.js'
import NotFound from './NotFound.js'

function App() {
  const reload = () => window.location.reload();
  return (
    <div className="App">
      <header className="App-header">
      </header>
      <div className="App-content">
        <BrowserRouter>
          <div>
            <Switch>
              <Route exact path='/hotels/:hotelId' component={Main} />
              <Route exact path='/robots.txt' onEnter={reload} />
              <Route component={NotFound} />
            </Switch>
          </div>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;