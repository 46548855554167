import React from 'react';

function hotelStarCategory(hotel) {
	const category = hotel.basicInfo.category
	return (
		<section>
			<h3>Star Category:</h3>
			<p>{category}</p>
		</section>
	)
}

function hotelAddress(hotel) {
	const location = hotel.basicInfo.location
	return (
		<section className="address-block">
			<p>{location.address1}</p>
			<p>{location.city}, {location.stateOrProvince} {location.postalCode}, {location.country}</p>
		</section>
	)
}

function hotelRezgateHotelId(rezgateHotelId) {
	return (
		<section className="rezgatehotelid-block">
			<p>Rezgate Hotel Id: {rezgateHotelId}</p>
		</section>
	)
}


function hotelShortDescription(hotel) {
	const description = hotel.basicInfo.shortDescription
	// Remove HTML Tags from description
	const cleanedDescription = description.replace(/(<([^>]+)>)/ig,"");
	return (
		<section>
			<h3>Hotel Short Description:</h3>
			<p>{cleanedDescription}</p>
		</section>
	)
}


function hotelLongDescription(hotel) {
	const description = hotel.extendedContent.longDescription
	const cleanedDescription = description
	return (
		<section>
			<h3>Hotel Long Description:</h3>
			<div dangerouslySetInnerHTML={{ __html: cleanedDescription }} />
		</section>
	)
}

function hotelRoomDescriptions(hotel) {
	const roomTypes = hotel.roomTypes
	const roomDescriptions = roomTypes.map(
		roomType => <div key={roomType.id}>{hotelRoomDescription(roomType)}</div>
	)

	return (
		<section>
			<h3>Room Descriptions:</h3>
			{roomDescriptions}
		</section>
	)
}

function hotelRoomDescription(roomType) {

	const roomName = roomType.name
	const roomDescription = roomType.basicInfo.description
	return (
		<section>
			<h3>{roomName}</h3>
			<div dangerouslySetInnerHTML={{ __html: roomDescription }} />
		</section>
	)
}

function hotelAmenities(hotel) {
	const amenities = hotel.amenities.includedAmenities
	const amenityItems = amenities.map(
		amenity => <p key={amenity.id}>{amenity.name}</p>
	)

	// Remove HTML Tags from description
	return (
		<section>
			<h3>Amenities:</h3>
			{amenityItems}
		</section>
	)
}

function hotelBanner(hotel) {
	return (
		<article>
			<img src={`https://www.bonotel.com/api/Assets/hotel/${hotel.id}/main/fullwidthtwocolumn`} alt="Hero Banner"></img>
		</article>
	)
}

class Hotel extends React.Component {
	render() {
		const hotel = this.props.hotel

		return (
			<section className="hotel-block">
				<h1 className="header">{hotel.name}</h1>
				{hotelRezgateHotelId(hotel.rezgateHotelId)}
				{hotelAddress(hotel)}
				<section className="main-block">
					<section className="left-block">
						{hotelBanner(hotel)}
						{hotelStarCategory(hotel)}
						{hotelShortDescription(hotel)}
						{hotelAmenities(hotel)}
						{hotelLongDescription(hotel)}
					</section>
					<section className="right-block">
						{hotelRoomDescriptions(hotel)}
					</section>
				</section>
			</section>
		)
	}
}

export default Hotel;