
import React from 'react';
import 'whatwg-fetch';
import Hotel from "./Hotel"

const PC_PCAPS_BACKEND_HOST = process.env.REACT_APP_PC_PCAPS_BACKEND_HOST;
const PC_PCAPS_BACKEND_PORT = process.env.REACT_APP_PC_PCAPS_BACKEND_PORT;
const PC_PCAPS_BACKEND_ROOT_URL = `${PC_PCAPS_BACKEND_HOST}:${PC_PCAPS_BACKEND_PORT}`;

class NotFound extends React.Component {
  render() {
    return <p>NOT FOUND</p>
  }
}

const STANDARD_HEADERS = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
};

const STANDARD_GET_OPTIONS = {
  method: "GET",
  headers: STANDARD_HEADERS,
}


class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hotel: null,
      error: null
    }
  }

  componentWillMount() {
    const rezgateHotelId = this.props.match.params.hotelId
    this.fetchHotelDataByRezgateId(rezgateHotelId)
  }

  async fetchHotelDataByRezgateId(rezgateHotelId) {
    const url = `${PC_PCAPS_BACKEND_ROOT_URL}/api/v1/hotels?rezgateId=${rezgateHotelId}`;

    let res = await fetch(url, STANDARD_GET_OPTIONS)

    if (res.status === 200) {
      let response = await res.json()
      if (response == undefined || response['hotels'].length == 0){
        console.log({ error: response.error })
        this.setState({ error: "There was an issue in fetching Rezgate Hotel Id: " + rezgateHotelId })
      } else {
        let pcmsHotelId = response['hotels'][0]['id']
        this.fetchHotelData(pcmsHotelId, rezgateHotelId)
      }
    } else if (res.status === 404) { // 404 NOT FOUND Error from PCAPS
      this.setState({ error: "Rezgate Hotel Id: " + rezgateHotelId + " not found."})
    } else { // Other error
      this.setState({ error: "There was an issue in fetching Rezgate Hotel Id: " + rezgateHotelId })
    }
  }

  async fetchHotelData(pcmsHotelId, rezgateHotelId) {
    const url = 'https://www.bonotel.com:443/api/pcms/v2/hotels/'+pcmsHotelId+'?numRecords=16&include=basicInfo,roomTypes,amenities,distributionMapping,extendedContent,highlights,roomTypes.basicInfo,roomTypes.amenities,roomTypes.distributionMapping'
    let res = await fetch(url, STANDARD_GET_OPTIONS)
    if (res.status === 200) {
      let hotel = await res.json()
      // Set Rezgate hotel Id
      hotel['rezgateHotelId'] = rezgateHotelId
      this.setState({ hotel: hotel, error: null })
    } else {
      this.setState({ hotel: {}, error: null })
    }
  }

  render() {
    let appContent = ''

    if (this.state.error != null) {
      appContent = <article>
        <h2>ERROR</h2>
        <p>{this.state.error}</p>
      </article>
    } else if (this.state.hotel && Object.keys(this.state.hotel).length === 0) {
      appContent = <NotFound />
    } else if (this.state.hotel !== null) {
      appContent = (
        <article>
          <Hotel hotel={this.state.hotel} />
        </article>
      )
    }

    return (
      <div className="App">
        <header className="App-header">
          {appContent}
        </header>
      </div>
    );
  }
}


export default Main;