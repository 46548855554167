import React from 'react';

class NotFound extends React.Component {
  render() {
    return <p>NOT FOUND</p>
  }
}

export default NotFound;

